import {XhrService} from "../../default/global/ts/xhr.service";

export function triggerFilegalleryFilter(nodes = document.querySelectorAll(".js-filegalleryFilter")) {
    nodes.forEach(node => {
        const filterForm = node.querySelector("form.filegalleryFilter")
        const list = node.querySelector('.filegallery');

        if(filterForm && list){
            filterForm.addEventListener('change', e => {
                const checked = filterForm.querySelector(':checked')
                const category = checked.value;
                if (category === 'all') {
                    list.classList.remove('filegallery--filter-active')
                    list.querySelectorAll(`.filegalleryItem.active`).forEach(item => {
                        item.classList.remove('active')
                    })
                }
                else {
                    list.classList.add('filegallery--filter-active')
                    list.querySelectorAll(`.filegalleryItem.active`).forEach(item => {
                        item.classList.remove('active')
                    })
                    list.querySelectorAll(`.filegalleryItem[data-category='${category}']`).forEach(item => {
                        item.classList.add('active')
                    })
                }
            })
        }
    })
}

function filegalleryFilter(category, beforeSend, successSend, errorSend) {

    if (typeof beforeSend !== 'undefined') {
        beforeSend();
    }

    XhrService.generalAjaxCall('filter_filegallery', [
        category
    ], 'POST', 'json').then(res => {
        if (typeof successSend !== 'undefined') {
            successSend(res);
        }
    }, err => {
        console.error(err);
        if (typeof errorSend !== 'undefined') {
            errorSend();
        }
    })
}